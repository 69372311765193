.blipStatus {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--text-color-accent);
  font-size: 1.2rem;
  font-weight: 500;
  --blip-in: --tertiary;
  --blip-out: #7BE728C9;
  --blip-size: 18px;

  .blip {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: calc(var(--blip-size) * -0.5);

    div {
      width: var(--blip-size);
      height: var(--blip-size);
      background: var(--blip-in);
      border-radius: 100%;
      margin: 0;
      padding: 0;
      position: relative;
      left: calc(-1 * var(--blip-size));
    }

    &::before {
      content: "";
      display: block;
      background: var(--blip-out);
      width: var(--blip-size);
      height: var(--blip-size);
      border-radius: 100%;
      margin: 0;
      padding: 0;
      animation: growAndShrink 2s infinite ease-out;
    }
  }

  @keyframes growAndShrink {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1.8);
      opacity: 0;
    }
  }
}