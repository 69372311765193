@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');
$pFont: 'Poppins', sans-serif;
//$pFont: 'Baloo 2', cursive;
$bg1: #121212;
//$bg1: #333551;
$bg2: #202020;
//$bg2: #5B5E84;
$bgGradient: linear-gradient(180deg, #010007 -16.33%, #000B40 24.67%, #02FEFF 100%);
$white: #FFF;
$pColor: #FC96CE;
$sColor: #01BFDB;