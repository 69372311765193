

@import '../../variables.scss';
.historyContainer {
    background-color: --bg1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.tableWrapper {
    background-image: (url("../../assets/staking/itemsBG.svg"));
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 100px;
    margin: 80px 0 150px 0;
    width: 1114px;
    height: 713px;
    margin-top: 50px;
    padding: 60px 40px 50px 70px;
}

.historyTable {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90%;
    overflow-y: scroll;
    padding: 20px;

    /* width */
    &::-webkit-scrollbar {
        width: 15px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #333551;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #8F94C8;
        border-radius: 10px;
        border: 4px solid #333551;
        height: 10px !important;
        cursor: pointer;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #7b7faa;
        cursor: pointer;
    }
}

.tableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 95%;
    height: 50px;
    background-color: #000000d5;
    border-radius: 15px;
    padding: 0 20px;
    margin: 0 auto 10px 20px;
    color: white;
    font-size: 20px;
    font-weight: 700;

    p {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

.tableRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: #00000080;
    border-radius: 15px;
    padding: 0 20px;
    margin-bottom: 10px;
    color: white;
    font-size: 20px;
    font-weight: 700;

    p {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

.background {
    display: none;
}

@media screen and (max-width: 767px) {
    .historyContainer {
        padding-top: 80px;
    }
}

@media screen and (max-width: 560px) {
    .tableWrapper {
        position: relative;
        width: 98vw;
        height: 95vh;
        padding: 52px;
        margin-top: 20px;
        background-image: none;
    }

    .background {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
        z-index: -1;
        color: #767b7c6e
    }

    .historyTable {
        padding: 0;
        overflow-y: scroll;
        scroll-snap-type: proximity;
        scroll-snap-align: end;
        height: 88%;

        /* width */
        &::-webkit-scrollbar {
            width: 0;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #333551;
            border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #8F94C8;
            border-radius: 10px;
            border: 4px solid #333551;
            height: 10px !important;
            cursor: pointer;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #7b7faa;
            cursor: pointer;
        }
    }

    .tableHeader {
        font-size: x-small;
        margin: 0;
        width: 280px;
        height: max-content;
        margin: 30px auto 0 auto;

        p {
            margin: 10px 0;
        }
    }

    .tableRow {
        font-size: x-small;
        margin: 0;
        width: 280px;
        height: max-content;
        margin: 10px auto 10px auto;

        p {
            margin: 10px 0;
        }
    }
}


@media screen and (max-width: 350px) {

    

    .tableWrapper {
        position: relative;
        width: 98vw;
        height: 95vh;
        padding: 40px;
        margin-top: 40px;
        background-image: none;
    }

    .background {
        display: block;
        position: absolute;
        top: -50px;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
        z-index: -1;
        color: #767b7c6e
    }

    .historyTable {
        padding: 0;
        overflow-y: scroll;
        scroll-snap-type: proximity;
        scroll-snap-align: end;
        height: 75%;

        /* width */
        &::-webkit-scrollbar {
            width: 0;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #333551;
            border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #8F94C8;
            border-radius: 10px;
            border: 4px solid #333551;
            height: 10px !important;
            cursor: pointer;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #7b7faa;
            cursor: pointer;
        }
    }

    .tableHeader {
        font-size: x-small;
        margin: 0;
        width: 100%;
        height: max-content;
        margin: 10px auto 0 auto;

        p {
            margin: 10px 0;
        }
    }

    .tableRow {
        font-size: x-small;
        margin: 0;
        width: 100%;
        height: max-content;
        margin: 10px auto 10px auto;

        p {
            margin: 10px 0;
        }
    }
}