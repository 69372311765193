@import '../../variables.scss';

@keyframes pulse {
  0% {
    transform: scale(.9);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba($sColor, 0);
  }

  100% {
    transform: scale(.9);
    box-shadow: 0 0 0 0 rgba($sColor, 0);
  }
}

@keyframes pulseMobile {
  0% {
    transform: scale(.9);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba($sColor, 0);
  }

  100% {
    transform: scale(.9);
    box-shadow: 0 0 0 0 rgba($sColor, 0);
  }
}

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $bg1;
  padding: 100px 0;

  h2 {
    margin: 0;
    font-size: 5em;
    color: #fff;
    text-shadow: 2px 2px #000000;
  }
}

.roadMapContainer {
  position: relative;
  width: 100%;
  height: min-content;

  img {
    height: 100%;
    width: 100%;
  }

  //z-index: 101;
}

.roadmapDeatailsBg {
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: #0000006c;
  z-index: 10;
}

.roadmapDeatailsModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #000000d0;
  height: max-content;
  width: 50%;
  border-radius: 10px;
  padding: 40px;
  text-align: center;

  h2 {
    font-size: 2em;
    color: #FFF;
    text-shadow: 2px 2px #000000;
  }

  p {
    font-size: 1.5em;
    color: #FFF;
  }

  box-shadow: rgba(0, 0, 0, 0.432) 0px 10px 20px,
  rgba(0, 0, 0, 0.432) 10px 6px 6px;
}

@media only screen and (min-width: 1200px) {


  .pulse {
    position: relative;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    font-weight: light;
    text-transform: uppercase;
    text-align: center;
    line-height: 100px;
    color: white;
    border: none;
    border-radius: 50%;
    background: #01bedba8;
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba($sColor, .5);
    animation: pulse 1.5s infinite;
  }

  .rotateContainer {
    display: none;
    height: 0px;
    width: 0px;
  }

  .step0 {
    position: absolute;
    bottom: 20%;
    left: 14.5%;
    width: 1px;
    height: 1px;
    margin: 0 auto 0;
    perspective: 1000;
    background: transparent;
    border: none;
  }

  .step1 {
    position: absolute;
    bottom: 5%;
    left: 50%;
    width: 1px;
    height: 1px;
    margin: 0 auto 0;
    perspective: 1000;
    background: transparent;
    border: none;
  }

  .step2 {
    position: absolute;
    bottom: 80%;
    left: 80%;
    width: 1px;
    height: 1px;
    margin: 0 auto 0;
    perspective: 1000;
    background: transparent;
    border: none;
  }

  .step3 {
    position: absolute;
    bottom: 85%;
    left: 10%;
    width: 1px;
    height: 1px;
    margin: 0 auto 0;
    perspective: 1000;
    background: transparent;
    border: none;
  }

  .step4 {
    position: absolute;
    bottom: 45%;
    left: 40%;
    width: 1px;
    height: 1px;
    margin: 0 auto 0;
    perspective: 1000;
    background: transparent;
    border: none;
  }

  .step5 {
    position: absolute;
    bottom: 15%;
    left: 80%;
    width: 1px;
    height: 1px;
    margin: 0 auto 0;
    perspective: 1000;
    background: transparent;
    border: none;
  }

  .step6 {
    position: absolute;
    bottom: 80%;
    left: 60%;
    width: 1px;
    height: 1px;
    margin: 0 auto 0;
    perspective: 1000;
    background: transparent;
    border: none;
  }

  .step7 {
    position: absolute;
    bottom: 65%;
    left: 10%;
    width: 200px;
    height: 1px;
    margin: 0 auto 0;
    perspective: 1000;
    background: transparent;
    border: none;
  }

  .step8 {
    position: absolute;
    bottom: 70%;
    left: 42%;
    width: 1px;
    height: 1px;
    margin: 0 auto 0;
    perspective: 1000;
    background: transparent;
    border: none;
  }

  .step9 {
    position: absolute;
    bottom: 55%;
    left: 85%;
    width: 1px;
    height: 1px;
    margin: 0 auto 0;
    perspective: 1000;
    background: transparent;
    border: none;
  }
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
  .rotateContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #00000067;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    border-radius: 10px;
    padding: 20px;

    p {
      color: #FFF;
    }

    svg {
      fill: #FFF;
      height: 30px;
      width: 30px;
    }

  }

  .step0,
  .step1,
  .step2,
  .step3,
  .step4,
  .step5,
  .step6,
  .step7,
  .step8,
  .step9 {
    position: absolute;
    display: none;
    width: 0px;
    height: 0px;
  }
}

@media only screen and (min-width: 500px) and (max-width: 1200px) {



  .rotateContainer {
    display: none;
    height: 0px;
    width: 0px;
  }

  .step0 {
    position: absolute;
    bottom: 10%;
    left: 16%;
    width: 1px;
    height: 1px;
    margin: 0 auto 0;
    perspective: 1000;
    background: transparent;
    border: none;
  }

  .step1 {
    position: absolute;
    bottom: 1%;
    left: 60%;
    width: 1px;
    height: 1px;
    margin: 0 auto 0;
    perspective: 1000;
    background: transparent;
    border: none;
  }

  .step2 {
    position: absolute;
    bottom: 70%;
    left: 88%;
    width: 1px;
    height: 1px;
    margin: 0 auto 0;
    perspective: 1000;
    background: transparent;
    border: none;
  }

  .step3 {
    position: absolute;
    bottom: 75%;
    left: 20%;
    width: 1px;
    height: 1px;
    margin: 0 auto 0;
    perspective: 1000;
    background: transparent;
    border: none;
  }

  .step4 {
    position: absolute;
    bottom: 35%;
    left: 50%;
    width: 1px;
    height: 1px;
    margin: 0 auto 0;
    perspective: 1000;
    background: transparent;
    border: none;
  }

  .step5 {
    position: absolute;
    bottom: 5%;
    left: 90%;
    width: 1px;
    height: 1px;
    margin: 0 auto 0;
    perspective: 1000;
    background: transparent;
    border: none;
  }

  .step6 {
    position: absolute;
    bottom: 74%;
    left: 65%;
    width: 1px;
    height: 1px;
    margin: 0 auto 0;
    perspective: 1000;
    background: transparent;
    border: none;
  }

  .step7 {
    position: absolute;
    bottom: 55%;
    left: 10%;
    width: 200px;
    height: 1px;
    margin: 0 auto 0;
    perspective: 1000;
    background: transparent;
    border: none;
  }

  .step8 {
    position: absolute;
    bottom: 60%;
    left: 50%;
    width: 1px;
    height: 1px;
    margin: 0 auto 0;
    perspective: 1000;
    background: transparent;
    border: none;
  }

  .step9 {
    position: absolute;
    bottom: 45%;
    left: 98%;
    width: 1px;
    height: 1px;
    margin: 0 auto 0;
    perspective: 1000;
    background: transparent;
    border: none;
  }


  .pulse {
    position: relative;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    font-weight: light;
    text-transform: uppercase;
    text-align: center;
    line-height: 100px;
    color: white;
    border: none;
    border-radius: 50%;
    background: #01bedba8;
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba($sColor, .5);
    animation: pulseMobile 1.5s infinite;
  }

  .pulse-button:hover {
    animation: none;
  }
}

@media only screen and (max-width: 1200px) {


  .roadMapContainer {
    position: relative;
    width: 100%;
    height: min-content;

    margin: 50px 0;

    img {
      height: 100%;
      width: 100%;
    }

    //z-index: 101;
  }

  .roadmapDeatailsModal {
    position: fixed;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000000d0;
    height: max-content;
    width: 60%;
    border-radius: 10px;
    padding: 20px;
    text-align: center;

    h2 {
      font-size: 1.5em;
      color: #FFF;
      text-shadow: 2px 2px #000000;
    }

    p {
      font-size: 1em;
      color: #FFF;
    }

    box-shadow: rgba(0, 0, 0, 0.432) 0px 10px 20px,
    rgba(0, 0, 0, 0.432) 10px 6px 6px;
  }



  .titleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $bg1;
    padding: 0;

    h2 {
      margin: 0;
      font-size: 2em;
      color: #fff;
      text-shadow: 2px 2px #000000;
    }
  }
}