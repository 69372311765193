@import '../../variables.scss';

.raffle {
    display: flex;
    flex-direction: column;
    font-family: var(--font);
    margin-top: 80px;
    padding: 0 2rem;

    .onGoing {
        display: grid;
        place-items: center;
        margin-top: 1rem;
        justify-content: space-around;

        .noRaffles {
            color: var(--text-color-accent);
            font-size: 1.7rem;
            font-family: var(--font);
            font-weight: 600;
            margin-top: 0.2rem;
        }

        svg {
            aspect-ratio: 1/1;
            width: 100px;
            align-self: center;
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
    }

    @media screen and (max-width: 768px) {
        .onGoing {
            margin-top: unset;
            place-items: center;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

// Overrides:
.ant-tabs-tab-btn {
    font-family: var(--font);
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--text-color-accent);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    margin: 0 0.5rem;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: var(--background-color-accent);
        color: var(--text-color);
    }
}

.ant-tabs-ink-bar, .ant-tabs-ink-bar-animated {
    background-color: var(--primary) !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary) !important;
}

.ant-tabs-tab-btn:hover {
    color: var(--text-color) !important;
}

.ant-tabs-nav {
    align-self: center;

    &::before {
        opacity: 0;
    }
}

.ant-tabs-nav-operations {
    display: none !important;
}