.card {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    transition: all 200ms ease-in-out;
    width: 300px;
    background: var(--background-accent);
    border-radius: var(--border-radius);
    margin-bottom: 2rem;
    cursor: pointer;

    .status {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        border-radius: var(--border-radius) var(--border-radius) 0 0;
        height: 250px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        svg {
            fill: var(--primary);
            aspect-ratio: 1/1;
            min-width: 20px;
            max-width: 20px;
            margin-right: 5px;
        }

        .participants {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            color: var(--text-color);
            font-family: var(--font);
            font-size: 1rem;
            font-weight: 300;
            background: var(--background-accent);
            border-radius: var(--border-radius);
            min-width: 60px;
            height: 25px;
            padding: 0 0.5rem;
            margin-bottom: 7px;
            margin-left: 7px;
            order: -1;
        }

        .endingOn {
            display: flex;
            align-items: center;
            color: var(--text-color);
            font-family: var(--font);
            font-size: 1rem;
            font-weight: 300;
            background: var(--background-accent);
            border-radius: var(--border-radius);
            height: 25px;
            padding: 0 0.5rem;
            margin-bottom: 7px;
            margin-right: 7px;
        }
    }

    .statusTop {
        position: relative;
        height: 0;
        width: 50px;

        .raffleId {
            display: flex;
            align-items: center;
            align-self: flex-start;
            justify-content: center;
            color: var(--text-color);
            font-family: var(--font);
            font-size: 1rem;
            font-weight: 300;
            background: var(--background-accent);
            border-radius: var(--border-radius);
            height: 25px;
            margin-top: 7px;
            margin-left: 7px;
            padding: 0 10px;
            width: fit-content;
        }
    }

    .cardBody {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.6rem 1.4rem;

        p {
            color: var(--text-color-accent);
            font-family: var(--font);
            font-size: 1.2rem;
            font-weight: 400;
            margin: 0.15rem 0 0;
        }
        span {
            color: var(--primary);
            font-family: var(--font);
            font-size: 1.2rem;
            font-weight: 600;
            margin: 0.15rem 0 0;
        }

        h3 {
            color: var(--text-color);
            font-family: var(--font);
            font-size: 1.2rem;
            font-weight: 400;
            margin: 0.2rem 0 0;

            span {
                color: var(--primary);
                font-size: 1.4rem;
                font-weight: 600;
            }
        }

        .price {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
            justify-content: center;
            margin-bottom: 5px;

            svg {
                fill: var(--text-color-accent);
                aspect-ratio: 1/1;
                min-width: 20px;
                max-width: 20px;
                margin: 0 5px 0 0;
            }
        }

        .bottom {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 0.2rem 0 0;

            .cardStatus {
                color: var(--text-color);
                font-family: "Roboto", cursive;
                font-size: 1rem;
                font-weight: 300;
                background-color: var(--background-accent);
                border-radius: var(--border-radius);
                padding: 0 0.4rem;
            }

            button {
                background: var(--primary);
                background-size: 200%;
                border: none;
                border-radius: var(--border-radius);
                color: var(--text-color);
                cursor: pointer;
                font-family: var(--font);
                font-size: 1rem;
                font-weight: 500;
                margin: 0.4rem 0;
                padding: 0.3rem 1.2rem;
                height: 50px;
                transition: all 200ms ease-in-out;

                &:hover {
                    filter: brightness(1);
                }
            }

            h2 {
                color: var(--text-color-accent);
                font-family: var(--font);
                font-size: 0.8rem;
                font-weight: 400;
                margin: 0.6rem 0 0 0;
                align-self: center;

                span {
                    color: var(--primary);
                }
            }
        }
    }

    .cardBodyExc {
        background-image: linear-gradient(
                        rgba(20, 20, 20, 0.99) 0%,
                        rgba(18, 18, 18, 0.97) 35%,
                        rgba(18, 18, 17, 0.97) 65%,
                        rgba(20, 20, 20, 0.99) 99%),
        url('../../assets/lanu.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    &:hover {
        filter: brightness(1.2);

        button {
            filter: brightness(0.8);
        }
    }
}