.loginWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50%;
  width: 50%;
  margin-top: 6rem;
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: 760px) {
    height: 100%;
    width: 100%;
  }
}

.loadingWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;

  svg {
    aspect-ratio: 1/1;
    width: 75px;
  }

  h1 {
    color: var(--text-color);
    margin-top: 0.5rem;
    font-size: 1.4rem;
  }
}

.claimWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .claimRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;

    .NFTDisplay {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 50%;

      img {
        border-radius: var(--border-radius);
        width: 100%;
        aspect-ratio: 1/1;
      }

      .multiplierWrap {
        position: relative;
        align-self: flex-end;
        height: 0;
        width: 35px;
        margin: 0;
      }

      .multiplier {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: -25px;
        width: 45px;
        height: 45px;
        margin: 0;
        aspect-ratio: 1/1;
        color: var(--text-color);
        font-family: var(--font);
        font-weight: 600;
        background: linear-gradient(25deg, var(--primary), var(--tertiary));
        border-radius: 100%;
      }
    }

    .claimBox {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 2rem;
      width: 100%;

      .claim {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background: var(--background-accent);
        border-radius: var(--border-radius);
        padding: 1rem;

        h1 {
          color: var(--text-color);
          font-size: 1.8rem;
          align-self: center;
          margin: 0 0 1rem;
        }

        .statsRow {
          display: flex;
          flex-direction: row;
          width: 100%;

          .stats {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;

            p {
              color: var(--text-color-accent);
              font-size: 1.2rem;
              margin: 0 0 0 10px;
            }

            h2 {
              color: var(--primary);
              font-size: 1.4rem;
              margin: 0;
            }
          }
        }

        .info {
          color: var(--text-color-accent);
          font-size: 1.0rem;
          font-weight: 300;
          margin: 0;
        }

        .timeleft {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          border-top: 2px solid var(--background);
          margin-top: 1rem;

          .timeleftTitle {
            margin: 1rem 0 0;
          }
          .timeleftTag {
            font-size: 1rem;
            font-weight: 300;
            margin: 0 0 0.5rem;
          }

          h2 {
            color: var(--text-color);
            font-size: 1.3rem;
            align-self: center;
          }

          p {
            color: var(--text-color-accent);
            font-size: 1.2rem;
          }

          .time {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            width: 100%;

            h2 {
              color: var(--primary);
            }

            .unit {
              display: flex;
              flex-direction: column;

              p {
                margin: 0;
              }
            }
          }
        }
      }

      button {
        color: var(--text-color);
        font-family: var(--font);
        font-size: 1.5rem;
        font-weight: 600;
        background: var(--primary);
        border: none;
        border-radius: var(--border-radius);
        margin-top: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        min-height: 50px;
        cursor: pointer;
        transition: filter 200ms ease-in-out;

        &:hover {
          filter: brightness(1.2);
        }
      }
    }

    @media screen and (max-width: 960px) {
      flex-direction: column;
      justify-content: center;

      .NFTDisplay {
        position: relative;
        top: 20px;
        width: 25%;
      }

      .claimBox {
        .claim {
          h1 {
            margin-top: 1.2rem;
          }

          .statsRow {
            .stats {
              flex-direction: column;
            }
            
            p {
              text-align: center;
            }
          }
        }
      }
    }
  }

  .noNFTs {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 2rem;

    h1 {
      color: var(--text-color);
      font-size: 1.8rem;
      align-self: center;
      margin: 0 0 1rem;
    }

    h2 {
      color: var(--text-color-accent);
      font-size: 1.4rem;
      margin: 0;
    }

    @media screen and (max-width: 860px) {
      padding-left: 20px;
    }
  }
}