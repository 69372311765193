.loginWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50%;
  width: 50%;
  margin-top: 6rem;
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: 760px) {
    height: 100%;
    width: 100%;
  }
}

.loadingWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;

  svg {
    aspect-ratio: 1/1;
    width: 75px;
  }

  h1 {
    color: var(--text-color);
    margin-top: 0.5rem;
    font-size: 1.4rem;
  }
}

.mint2win-page-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 4rem;

  .logo {
    width: 220px;
    aspect-ratio: 1/1;
    filter: hue-rotate(314deg) saturate(5);
  }

  h1 {
    color: var(--text-color);
    font-size: 1.8rem;
    align-self: center;
    text-align: center;
    margin: 0;
  }

  h2 {
    color: var(--text-color-accent);
    font-size: 1.4rem;
    text-align: center;
    margin: 0;
  }

  .mint-portal {
    background: var(--background-accent);
    width: 100%;
    border-radius: var(--border-radius);
    margin-top: 20px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      color: var(--text-color);
      font-size: 1.2rem;
      align-self: center;
      text-align: center;
      margin: 0;
    }

    p {
      color: var(--text-color-accent);
      font-size: 1rem;
      align-self: center;
      text-align: center;
      margin: 0 0 1rem;
    }

    .mint-counter {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;

      button {
        background-color: var(--primary);
        color: var(--text-color);
        border: none;
        border-radius: var(--border-radius);
        font-size: 1.2rem;
        width: 40px;
        height: 40px;
        cursor: pointer;
        transition: all 200ms ease-in-out;

        &:hover {
          filter: brightness(1.2);
        }
      }

      span {
        font-size: 1.2rem;
        color: var(--text-color);
        margin: 0 1rem;
      }
    }

    .mint-button {
      height: 50px;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: var(--border-radius);
      background-color: var(--primary);
      color: var(--text-color);
      font-size: 1.2rem;
      font-weight: 500;
      font-family: var(--font);
      outline: none;
      align-self: center;
      border: none;
      -webkit-appearance: none;
      cursor: pointer;
      transition: all 200ms ease-in-out;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      svg {
        aspect-ratio: 1/1;
        width: 25px;
        margin-left: 10px;
        display: none;
      }

      &:hover {
        filter: brightness(1.2);
      }
    }

    .button-disabled {
      background: var(--text-color-accent);
      cursor: not-allowed;
      transition: unset;

      svg {
        display: block;
      }

      &:hover {
        filter: brightness(1);
      }
    }
  }

  @media screen and (max-width: 860px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.blip-comp {
  align-self: flex-start;
}