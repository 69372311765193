@import '../../variables.scss';
.stakingLogsContainer {
    background-color: --bg1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.logsWrapper {
    background-image: (url("../../assets/staking/itemsBG.svg"));
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 80px 0 150px 0;
    width: 1114px;
    height: 713px;
    margin-top: 50px;
    padding: 60px 50px 50px 70px;
}

.logs {
    height: 100%;
    overflow-y: auto;
    padding-right: 10px;

    /* width */
    &::-webkit-scrollbar {
        width: 15px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #333551;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #8F94C8;
        border-radius: 10px;
        border: 4px solid #333551;
        height: 10px !important;
        cursor: pointer;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #7b7faa;
        cursor: pointer;
    }
}

.log {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    place-items: start start;
    margin-bottom: 50px;


    img {
        height: 250px;
        width: 100%;
        object-fit: cover;
        border-radius: 15px;
        box-shadow: 2px 4px 10px #0000005b;
    }
}

.imgPlaceholder {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    border-radius: 15px;
    background-color: #7b7faa;
    box-shadow: 2px 4px 10px #0000005b;
}

.logInfo {
    h2 {
        font-size: x-large;
        font-weight: 600;
        text-shadow: 2px 2px 2px #000000c0;
        color: #FFFFFF;
        margin: 0;
    }

    p {
        font-size: medium;
        font-weight: 400;
        text-shadow: 2px 2px 2px #000000c0;
        color: #f7f7f7;
        margin: 0;
    }
}


.addLog {
    display: grid;
    grid-template-rows: repeat(4, max-content);
    gap: 20px;
    background-color: #0000008f;
    padding: 50px;
    border-radius: 15px;
    justify-content: space-between;
    margin-top: 50px;
}

input {
    width: 100% !important;
}

.imageInput {
    color: #FFFFFF;


}

input[type=file]::file-selector-button {

    background-color: #38383888;
    transition: all 0.2s ease-in-out;
    padding: 10px 20px;
    border-radius: 15px;
    color: #FFFFFF;
    border: none;
    cursor: pointer;

    &:hover {
        background-color: #383838;
        transition: all 0.2s ease-in-out;
    }
}

.submit {
    background-color: #38383888;
    transition: all 0.2s ease-in-out;
    padding: 10px 20px;
    border-radius: 15px;
    color: #FFFFFF;
    border: none;
    cursor: pointer;

    &:hover {
        background-color: #383838;
        transition: all 0.2s ease-in-out;
    }
}

.delete {
    background-color: #f55050f3;
    transition: all 0.2s ease-in-out;
    padding: 10px 20px;
    border-radius: 15px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    border: none;
    cursor: pointer;

    &:hover {
        background-color: #ff5757f3;
        transition: all 0.2s ease-in-out;
    }
}

.background {
    display: none;
}


@media screen and (max-width:767px) {

    .stakingLogsContainer {
        padding-top: 80px;
    }

    .logsWrapper {
        position: relative;
        width: 98vw;
        height: 95vh;
        padding: 52px;
        margin-top: 20px;
        background-image: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
        z-index: -1;
        color: #767b7c6e
    }

    .logs {
        margin-top: 10px;
        height: 100%;
        width: 50vw;
    }

    .log {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 500px) {
    .logs {
        margin-top: 15px;
        width: 55vw;
    }
}

@media screen and (max-width: 461px) {
    .logs {
        margin-top: 20px;
        width: 60vw;
    }
}

@media screen and (max-width: 400px) {
    .logs {
        margin-top: 50px;
        width: 70vw;
    }
}