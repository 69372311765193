.faqWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  img {
    border-radius: var(--border-radius);
  }

  h1, p {
    color: var(--text-color);
    font-family: var(--font);
    margin: 0;
  }

  h1 {
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 10px;
  }
  p {
    font-size: 1.2rem;
  }
  .bannerImage {
    aspect-ratio: 2635/1600;
    width: 50%
  }

  section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    margin-top: 120px;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 57.5%;
    }

    .customLogoBanners {
      width: 50%;
    }

    img, .svgAsImage {
      width: 40%
    }
  }

  @media screen and (max-width: 760px) {
    .bannerImage {
      width: 90%;
    }

    h1 {
      margin-top: 20px;
    }

    section {
      width: 95%;
      flex-direction: column;
      justify-content: center;

      div {
        width: 100%;
      }

      .customLogoBanners {
        width: 100%;
      }

      img, .svgAsImage {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}