@import '../../variables.scss';


.dashboardContainer {
  width: 100%;
  display: flex;
}

.dashboard {
  height: 100%;
}