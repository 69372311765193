@import '../../variables.scss';

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    z-index: -1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.videoContainer {
  position: relative;
  width: auto;
  height: 100%;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #00000096;
    border-radius: 100%;
    border: none;
    position: absolute;
    padding: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    fill: #FFF;
    transition: all .2s ease-in-out;

    svg {
      height: 50px;
      width: 50px;
    }

    &:hover {
      background-color: #1a1a1a96;
      fill: rgb(233, 233, 233);
      transition: all .2s ease-in-out;
    }
  }

  img {
    width: 100%;
    height: auto;
    object-fit: fill;
  }

  .metaVideo {
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center bottom;
    animation: fadeIn 2s;
  }
}


.bannerImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  animation: fadeOut 2s;
}

.playButton {
  animation: 2.5s fadeOut;
}


@media only screen and (max-width: 767px) {
  .videoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: #00000096;
      border-radius: 100%;
      border: none;
      position: absolute;
      padding: 10px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      fill: #FFF;
      transition: all .2s ease-in-out;

      svg {
        height: 25px;
        width: 25px;
      }

      &:hover {
        background-color: #1a1a1a96;
        fill: rgb(233, 233, 233);
        transition: all .2s ease-in-out;
      }
    }
  }
}