.chainSwitchContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
    max-width: 1200px;
    width: 100%;
    padding: 20px;

    & h2 {
        color: #ffffff;
    }
}

.switchChainButton {
    height: 100%;
    border-radius: var(--border-radius);
    background-color: var(--primary);
    border: none;
    font-size: 20px;
    font-family: var(--font);
    padding: 10px 20px;
    color: var(--text-color);
    cursor: pointer;
    //box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: filter 300ms ease-in-out;

    &:hover {
        filter: brightness(1.2);
    }
}

@media screen and (max-width: 768px) {
    .chainSwitchContainer {
        & h2 {
            font-size: 20px;
            text-align: center;
        }
    }
}