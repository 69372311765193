.recentWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;
  margin-top: 2.5rem;
}

.recent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-self: center;
  background: var(--background-accent);
  // margin-bottom: 2rem;
  cursor: pointer;
  padding: 0.8rem 2rem;

  h1 {
    font-size: 1.8rem;
    font-weight: 500;
    color: var(--primary);
    font-family: var(--font);
  }

  p {
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--text-color-accent);
    font-family: var(--font);
  }
  .viewButton {
    display: flex;
    background: #3f3f3f;
    border-radius: var(--border-radius);
    padding: 0.5rem;
    svg {
      fill: var(--background-accent);
      aspect-ratio: 1/1;
      width: 25px;
      margin: 0;
      cursor: pointer;
    }

    &:hover {
      filter: brightness(1.2);
    }
  }

  &:hover {
    filter: brightness(1.2);
  }

  @media (max-width: 860px) {
    p {
      display: none;
    }
  }
}

.all {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  align-self: center;
  padding: 1rem 2rem;
  background: #282828;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  height: 0;
  overflow: hidden;
  transition: all 300ms ease-out;

  p {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--text-color-accent);
    font-family: var(--font);
    margin: 0.5rem 0;

    img {
      cursor: auto !important;
      width: 30px !important;
      height: 30px !important;
      aspect-ratio: 1/1 !important;
      border-radius: 100% !important;
      border: 2px solid var(--primary) !important;
      margin: 0 !important;
      margin-right: 12px !important;
      transition: none !important;

      &:hover {
        filter: none !important;
      }
    }

    span {
      font-size: 1.2rem;
      color: var(--primary);
      font-weight: 500;
      margin-left: auto;
    }
  }
  img {
    aspect-ratio: 1/1;
    width: 200px;
    border-radius: var(--border-radius);
  }
}

.allExpanded {
  height: auto;
  transition: all 300ms ease-in;

  svg {
    aspect-ratio: 1/1;
    align-self: center;
    width: 25px;
  }
}

.tickets {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
  font-size: 1.5rem;
  font-weight: 400;
  width: 100%;
  min-height: 80px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: var(--background-accent);
  border-radius: var(--border-radius);
  padding: 15px 1.5rem;
  cursor: pointer;

  svg {
    aspect-ratio: 1/1;
    width: 50px;
    fill: var(--primary);
    margin: 0;
  }

  span {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
  }
}