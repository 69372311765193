@import '../../variables.scss';

@keyframes randomFill {
  0% {
    fill: $pColor;
  }

  25% {
    fill: $sColor;
  }

  50% {
    fill: #D5FD8E;
  }

  100% {
    fill: #FBE76E;
  }
}


.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $bg1;
  padding: 100px 0;

  h2 {
    margin: 0;
    font-size: 5em;
    color: #fff;
    //text-shadow: 2px 2px #000000;
  }
}

.teamSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.teamContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 40px;
  padding-bottom: 150px;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.teamCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  border-radius: 15px;
  text-align: center;
  perspective: 1000px;

  h2 {
    font-size: 2em;
    color: #FFF;
    // text-shadow: 2px 2px #000000;
    margin: 0;
  }

  p {
    font-size: 1em;
    color: #FFF;
  }

  &:hover .cardInner {
    transform: rotateY(180deg);
  }

}

.cardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.nameContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
    svg {
      transition: fill 200ms ease-in-out;
    }
    &:hover {
      svg {
        fill: var(--primary);
        //animation: randomFill 4s ease-in-out infinite;
      }
    }
  }

  svg {
    margin-left: 15px;
    fill: #FFF;
    height: 25px;
    width: 25px;
    filter: drop-shadow(2px 2px 2px #000000);
  }
}


.avatarContainer,
.avatarContainerBack {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
  border-radius: 15px;
  overflow: hidden;
  //box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.avatarContainer {
  background-color: #bbb;
  color: black;

  img {
    height: 100%;
    width: 100%;
  }
}

.avatarContainerBack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  transform: rotateY(180deg);
  padding: 10px;

  p {
    margin: 0;
  }
}

@media only screen and (min-width:1160px) and (max-width:1400px) {
  .titleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $bg1;
    padding: 100px 0 50px;

    h2 {
      margin: 0;
      font-size: 5em;
      color: #fff;
      // text-shadow: 2px 2px #000000;
    }
  }

  .teamSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .teamContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 40px;
    padding-bottom: 150px;
  }

  .teamCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 250px;
    border-radius: 15px;
    text-align: center;
    perspective: 1000px;

    h2 {
      font-size: 2em;
      color: #FFF;
      // text-shadow: 2px 2px #000000;
      margin: 0;
    }

    p {
      font-size: xx-small;
      color: #FFF;
    }

    &:hover .cardInner {
      transform: rotateY(180deg);
    }

  }

  .cardInner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .nameContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      display: flex;

      svg {
        transition: fill 200ms ease-in-out;
      }

      &:hover {
        svg {
          fill: var(--primary);
          //animation: randomFill 4s ease-in-out infinite;
        }
      }
    }

    svg {
      margin-left: 15px;
      fill: #FFF;
      height: 25px;
      width: 25px;
      filter: drop-shadow(2px 2px 2px #000000);
    }
  }


  .avatarContainer,
  .avatarContainerBack {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    /* Safari */
    backface-visibility: hidden;
    border-radius: 15px;
    overflow: hidden;
    //box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }

  .avatarContainer {
    background-color: #bbb;
    color: black;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .avatarContainerBack {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.9);
    color: white;
    transform: rotateY(180deg);
    padding: 10px;

    p {
      margin: 0;
    }
  }
}

@media only screen and (min-width:868px) and (max-width:1159px) {
  .teamContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
    padding-bottom: 150px;
  }

  .teamCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 250px;
    border-radius: 15px;
    text-align: center;
    perspective: 1000px;

    h2 {
      font-size: 2em;
      color: #FFF;
      //text-shadow: 2px 2px #000000;
      margin: 0;
    }

    p {
      font-size: x-small;
      color: #FFF;
    }

    &:hover .cardInner {
      transform: rotateY(180deg);
    }

  }
}


@media only screen and (max-width: 767px) {
  .titleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $bg1;
    padding: 0;

    h2 {
      margin: 0;
      font-size: 2em;
      color: #fff;
      //text-shadow: 2px 2px #000000;
    }
  }

  .teamContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .teamCard {
    margin: 30px 0;
    display: flex;
    height: 350px;
    width: 350px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    text-align: center;
    perspective: 1000px;

    h2 {
      font-size: 2em;
      color: #FFF;
      //text-shadow: 2px 2px #000000;
      margin: 0;
    }

    p {
      font-size: 1em;
      color: #FFF;
    }

    &:hover .cardInner {
      transform: rotateY(180deg);
    }

  }

  .cardInner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .nameContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      display: flex;
      svg {
        transition: fill 200ms ease-in-out;
      }
      &:hover {
        svg {
          fill: var(--primary);
          //animation: randomFill 4s ease-in-out infinite;
        }
      }
    }

    svg {
      margin-left: 15px;
      fill: #FFF;
      height: 25px;
      width: 25px;
      // filter: drop-shadow(2px 2px 2px #000000);
    }


  }


  .avatarContainer,
  .avatarContainerBack {
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }

  .avatarContainer {
    background-color: #bbb;
    color: black;
    height: 350px;
    width: 350px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .avatarContainerBack {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #0000005b;
    color: white;
    transform: rotateY(180deg);
    padding: 10px;

    p {
      margin: 0;
      font-size: small;
    }
  }

}