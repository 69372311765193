@import '../../variables.scss';

.stakingModalContainer {
    position: fixed;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.stakingModalBG {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #0e0f18e1;
}

.stakingModalContent {
    display: flex;
    justify-content: space-evenly;
    background-color: $bg1;
    height: 40%;
    width: 45%;
    border-radius: 15px;
    padding: 25px 15px;
    color: #ffffff;
    z-index: 2;
}

.stakingModalEaContent {
    display: flex;
    justify-content: center;
    background-color: $bg1;
    height: 40%;
    width: 45%;
    border-radius: 15px;
    padding: 25px 15px;
    color: #ffffff;
    z-index: 2;
}

.stakingModalAction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 100%;
    font-size: 20px;

    button {
        color: #ffffff;
        font-weight: 600;
        background-color: rgba(0, 0, 0, 0.26);
        border: none;
        transition: all .2s ease-in-out;
        margin-top: 16px;
        border-radius: 10px;
        padding: 15px;
        width: 120px;
        cursor: pointer;

        &:hover {
            opacity: .85;
            transition: all .2s ease-in-out;
        }
    }

    &:nth-child(2) {
        border-left: 1px solid #ffffff;
        border-right: 1px solid #ffffff;
        padding: 0 25px;
        width: 40%;
    }
}

.stakingModalEaAction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    font-size: 20px;

    button {
        color: #ffffff;
        font-weight: 600;
        background-color: rgba(0, 0, 0, 0.26);
        border: none;
        transition: all .2s ease-in-out;
        margin-top: 16px;
        border-radius: 10px;
        padding: 15px 25px;
        width: 150px;
        cursor: pointer;

        &:hover {
            opacity: .85;
            transition: all .2s ease-in-out;
        }
    }
}

.openDrop {
    background-color: rgba(0, 0, 0, 0.904);
    border-radius: 15px;
    padding: 10px;
    width: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    transition: background-color .2s ease-in-out;

    &:hover {
        background-color: rgba(90, 90, 90, 0.452);
        border-radius: 15px;
        transition: background-color .2s ease-in-out;
    }
}

.drop {
    position: relative;
    height: max-content;
    width: 50%;

    .dropOuter {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dropInner {
        background-color: rgba(0, 0, 0, 0.904);
        border-radius: 0 0 15px 15px;
        width: 100%;
        position: absolute;
        top: -8px;
        border-top: 1px solid #ffffff;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        z-index: 2;
        transition: all 0.2s ease-in-out;

        .dropItem {
            display: flex;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 10px 0;
            cursor: pointer;
            transition: background-color .2s ease-in-out;

            &:hover {
                background-color: rgba(90, 90, 90, 0.452);
                transition: background-color .2s ease-in-out;
            }

            &:last-of-type {
                border-radius: 0 0 15px 15px;
            }
        }
    }

}


@media screen and (max-width: 767px) {

    .stakingModalContent,
    .stakingModalEaContent {
        width: 80%;
        height: 75%;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

    }

    .stakingModalAction,
    .stakingModalEaAction {
        width: 100%;
        padding: 20px 0;

        &:nth-child(2) {
            border-top: 1px solid #ffffff;
            border-bottom: 1px solid #ffffff;
            border-left: none;
            border-right: none;
            padding: 20px 0;
            width: 100%;
        }
    }
}