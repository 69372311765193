@import './variables.scss';

@keyframes moveBorder {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 25%;
  }

  100% {
    background-position: 0% 50%;
  }
}


body {
  height: 100%;
  font-family: $pFont;
  margin: 0;
  background-color: $bg1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  --border-radius: 12px;
  // V3:
  --primary: #1D9BF0;
  --secondary: #0084B4;
  --tertiary: #1DCAFF;
  --red: #b21515;

  // V2:
  //--primary: #0aab15;
  //--secondary: #8c982e;
  //--tertiary: #7be728;
  //--red: #b21515;
  --border: 2px solid $bg2;
  --background: #121212FF;
  --background-accent: #202020;
  --text-color: white;
  --text-color-accent: #808080;
  --font: 'Poppins', sans-serif;

  // V1:
  //--background: $bg1;
  //--font: 'Roboto', cursive;
}

* {
  box-sizing: border-box;
}


.Kelta {
  border: 4px #FBE76E solid;
  height: 250px;
  width: 250px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.Casa {
  border: 4px #D5FD8E solid;
  height: 250px;
  width: 250px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.Manu {
  border: 4px $pColor solid;
  height: 250px;
  width: 250px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.Lanu {
  border: 4px $sColor solid;
  height: 250px;
  width: 250px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.KeltaDetails {
  position: relative;
  height: auto;
  width: 100%;

  img {
    border: 4px #FBE76E solid;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.oneofOneDetails {
  position: relative;
  height: auto;
  width: 100%;

  img {
    border: 4px transparent solid;
    background-size: 200% auto;
    background-image: repeating-linear-gradient(to right, $pColor, $sColor, #D5FD8E, #FBE76E);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    animation: moveBorder 4s infinite;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.CasaDetails {
  position: relative;
  height: auto;
  width: 100%;

  img {
    border: 4px #D5FD8E solid;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
}

.ManuDetails {
  position: relative;
  height: auto;
  width: 100%;


  img {
    height: 100%;
    width: 100%;
    border: 4px $pColor solid;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    object-fit: cover;
  }
}

.LanuDetails {
  position: relative;
  height: auto;
  width: 100%;

  img {
    border: 4px $sColor solid;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

//Scroll Bar
body::-webkit-scrollbar {
  width: 0.4em;
}

body::-webkit-scrollbar-track {
  background: var(--background-accent);
}

body::-webkit-scrollbar-thumb {
  background: #1D9BF0;
  border-radius: var(--border-radius);
}