@import '../../variables.scss';

.modalContainer {
  position: relative;
}

.modalBG {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000091;
  z-index: 1;
  backdrop-filter: brightness(0.8) blur(10px);
}

.modal {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  width: 50%;
  border-radius: 10px;
  background-color: $bg1;
  display: flex;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  z-index: 2;
}

.connectButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-right: 1px solid rgba(255, 255, 255, 0.075);
  border-bottom: none;
  padding: 20px 40px;
  height: max-content;

  &:last-of-type {
    border: none;
  }

  &:hover {
    img {
      opacity: .8;
      transition: all 0.4s ease;
    }
  }

  img {
    height: 100px;
    width: 100px;
    transition: all 0.4s ease;
  }

  &:disabled {

    cursor: not-allowed;

    img {
      opacity: .5 !important;
    }
  }

}


.divider {
  border-left: 2px $bg2 solid;
  border-right: none;
  border-top: none;
  border-bottom: none;
}

.errorMessageContainer {
  position: fixed;
  display: flex;
  justify-content: flex-start;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  border-radius: 10px;
  padding: 20px;
  background-color: #e64040;
  color: #FFF;
  z-index: 2;

  svg {
    fill: #FFF;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .modal {
    top: 55%;
    width: 80%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .errorMessageContainer {
    width: 80%;
    top: 30%;
  }

  .connectButton {
    border-bottom: 1px solid rgba(255, 255, 255, 0.075);
    border-right: none;
    padding: 20px 0;
    width: max-content;

    &:last-of-type {
      border: none;
    }
  }
}