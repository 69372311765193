.stakingContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: #121212ff;
}

.moon {
  position: absolute;
  height: 50%;
  top: -25%;
  right: -12%;
}

.welcomeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 60%;
  width: 60%;
  background-image: url("../../assets/staking/welcomeBG.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.welcomeActions {
  margin-top: 100px;
  text-align: center;
  color: #ffffff;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 75%;
  width: 100%;
  max-width: 1000px;
  padding: 120px;
}

.infoActions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #a1a1a113;
  border-radius: 20px;
  padding: 40px;
  width: 100%;
  color: #ffffff;
  margin-bottom: 10px;
}

.primaryButton {
  color: #000000;
  font-size: larger;
  font-weight: 700;
  background-image: url("/assets/staking/primaryButton.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: 0 auto;
  cursor: pointer;
  padding: 10px 40px;
  width: max-content;
  border-radius: 25px;
  transition: all 0.4s ease-in-out;

  &:hover {
    opacity: 0.85;
    transition: all 0.4s ease-in-out;
  }
}

.button {
  border: none;
  color: #ffffff;
  font-size: larger;
  font-weight: 700;
  background-color: #0aab15;
  margin: 0 auto;
  cursor: pointer;
  padding: 15px 50px;
  width: max-content;
  border-radius: 12px;
  transition: all 0.4s ease-in-out;

  &:hover {
    opacity: 0.85;
    transition: all 0.4s ease-in-out;
  }
}

.infoButton {
  border: none;
  position: fixed;
  bottom: 2%;
  right: 2%;
  cursor: pointer;
  height: 50px;
  width: 50px;
  fill: #0aab15;

  &:hover {
    opacity: 0.85;
    transition: all 0.4s ease-in-out;
  }
}

.stoneTablet {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 767px) {

    .stakingContainer {
        align-items: flex-start;
    }

  .moon {
    height: 30%;
    top: -60px;
    right: -15%;
  }

  .welcomeContainer {
    height: 100%;
    width: 100%;
  }

  .welcomeActions {
    margin-top: 50px;

    h1 {
      font-size: larger;
    }

    h3 {
      font-size: x-small;
    }
  }

  .primaryButton {
    padding: 10px 15px;
    font-size: small;
  }

  .infoButton {
    height: 50px;
    width: 50px;
  }

  .infoContainer {
    max-width: 100%;
    padding: 25px;
    justify-content: flex-start;
    height: max-content;
  }

  .infoActions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max-content;
  }

  .infoActionsInner {
    padding: 10px;

    &:first-child p {
      margin-top: 0;
    }
  }

  .button {
    padding: 10px 15px;
    font-size: small;
  }
}
