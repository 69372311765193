.announcementOverlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(var(--background-rgb), 0.1);
  backdrop-filter: blur(10px);
  z-index: 999;
  animation: fadeIn 0.3s ease-in-out;

  .announcementContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);
    background-color: var(--background);
    padding: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    min-width: 50%;
    min-height: 20%;

    svg {
      width: 25px;
      aspect-ratio: 1/1;
      fill: var(--text-color);
      cursor: pointer;
      transition: filter 0.2s ease-in-out;
      align-self: flex-end;

      &:hover {
        filter: brightness(1.2);
      }
    }

    img {
      width: 220px;
      aspect-ratio: 1/1;
      filter: hue-rotate(314deg) saturate(5);
    }

    h1 {
      align-self: center;
      color: var(--text-color);
      font-size: 1.3rem;
      font-weight: 500;
      font-family: var(--font);
      margin: 0 0 10px;
    }

    p {
      color: var(--text-color-accent);
      font-size: 1.1rem;
      font-weight: 400;
      font-family: var(--font);
      margin: 0;
      align-self: center;
    }

    a, button {
      height: 50px;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: var(--border-radius);
      background-color: var(--primary);
      color: var(--text-color);
      font-size: 1.2rem;
      font-weight: 500;
      font-family: var(--font);
      outline: none;
      align-self: center;
      border: none;
      -webkit-appearance: none;
      cursor: pointer;
      transition: filter 200ms ease-in-out;
      margin-top: 20px;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        filter: brightness(1.2);
      }
    }
  }

  @keyframes fadeIn {
    from {
      transform: scale(0.8);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  @media screen and (max-width: 960px) {
    .announcementContent {
      min-width: 80%;
    }
  }
}