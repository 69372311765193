@import '../../variables.scss';

.rarityContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px 0 100px 0;

}

.successContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
  padding: 10px;
  background-color: #39ca00;
  border-radius: 10px;
  width: 80%;
  color: #FFF;

  svg {
    fill: #FFF;
    height: 25px;
    width: 25px;
    margin-right: 10px;
  }
}

.errorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
  padding: 10px;
  background-color: #e64040;
  border-radius: 10px;
  width: 80%;
  color: #FFF;

  svg {
    fill: #FFF;
    height: 25px;
    width: 25px;
    margin-right: 10px;
  }
}

.titleContainer {
  margin: 20px 0;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  h2 {
    font-size: xx-large;
    color: #FFF;
    text-shadow: 2px 2px #000000;
  }

  hr {
    margin: 0;
    border-bottom: 2px $bg2 solid;
    border-right: none;
    border-left: none;
    border-top: none;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.search {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;

  input {
    font-family: $pFont;
    font-weight: 500;
    padding: 10px;
    font-size: larger;
    border-radius: 4px;
    margin-right: 10px;
    background-color: #00000054;
    border: none;
    color: #FFF;
    box-shadow: rgba(0, 0, 0, 0.23) 0px 2px 2px;
  }

  button {
    font-family: $pFont;
    font-weight: 500;
    font-size: larger;
    background-color: #00000054;
    border: none;
    border-radius: 10px;
    padding: 10px;
    color: #FFF;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.23) 0px 2px 2px;
    transition: all 0.4s ease-in-out;
    margin-right: 10px;

    &:hover {
      background-color: #18181854;
      transition: all 0.4s ease-in-out;
    }

    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  }
}

.notFoundContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000054;
  border-radius: 10px;
  padding: 40px;
}

.notFound {
  color: #FFF;

  ul {
    padding: 0 0 0 20px;
    list-style: disc;
  }
}

.exampleText {
  display: flex;
  justify-content: space-between;
}

.exampleTextInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .correct {
    svg {
      fill: #39ca00;
    }
  }

  .incorrect {
    svg {
      fill: #e64040;
    }
  }

  p {
    display: flex;
    align-items: center;

    svg {
      height: 25px;
      width: 25px;
      margin-right: 10px;
    }
  }

}



.invalidFormat {
  text-decoration: line-through;
}

.collectionsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 50px;
  width: 80%;
}

.collectionCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 10px 0;
  text-decoration: none;
  line-break: anywhere;
  width: 100%;
  background-color: #00000054;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  transition: all 0.4s ease-in-out;

  h4 {
    color: #fff;
    text-shadow: 2px 2px #000000;
  }

  img {
    background-color: rgb(255,
        255,
        255);
    height: 100%;
    width: 100%;
    border-radius: 10px 10px 0 0;
  }

  &:hover {
    transform: rotate(2deg);
    background-color: #00000033;
    transition: all 0.4s ease-in-out;
  }
}

@media only screen and (max-width: 1200px) {
  .collectionsContainer {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

}

@media only screen and (max-width: 767px) {

  .titleContainer {
    flex-direction: column;
    width: 95%;
  }

  .title {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .search {
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;

    input {
      width: 75%;
    }

    button {
      width: 30%;
    }
  }

  .collectionsContainer {
    width: 95%;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .notFoundContainer {
    width: 95%;
    padding: 10px;
  }

  .notFound {
    h2 {
      font-size: medium;
    }

    ul {
      list-style: none;
      padding: 0;
    }
  }

  .exampleText {
    font-size: small;

    p {

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  .exampleTextInner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .successContainer {
    width: 95%;
    margin: 40px 0 -20px 0;

    svg {
      height: 50px;
      width: 50px;
    }
  }

  .errorContainer {
    width: 95%;
    margin: 40px 0 -20px 0;
  }

}