.raffleInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
  padding: 0 4rem 0;

  .loadingWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;

    svg {
      aspect-ratio: 1/1;
      width: 75px;
    }

    h1 {
      color: var(--text-color);
      margin-top: 0.5rem;
      font-size: 1.4rem;
    }
  }

  h1 {
    color: var(--text-color);
    font-size: 2.5rem;
    font-weight: 500;
    margin: 0;
  }

  h2 {
    color: var(--text-color);
    font-size: 2rem;
    font-weight: 500;
    margin: 0;
  }

  p {
    color: var(--text-color);
    font-size: 1rem;
    font-weight: 300;
    margin: 0;
  }

  svg {
    margin-bottom: 2rem;
    aspect-ratio: 1/1;
    width: 140px;
  }

  span {
    color: var(--primary);
    font-size: 2.5rem;
    font-weight: 600;
  }

  .title span {
    transition: color 300ms ease-in-out;
    cursor: pointer;

    &:hover {
      color: var(--secondary);
      filter: brightness(1.2);
    }
  }

  .infoPanel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 4rem;
    margin-bottom: 4rem;


    .divider {
      width: 100%;
      height: 2.5px;
      background-color: var(--primary);
      margin: 0.5rem 0;
    }

    .infoPanelLeft {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 50%;
      .title {
        display: none;
      }
      .titleName {
        display: none;
      }

      h2 {
        font-size: 1.2rem;
        font-weight: 300;
        margin: 0;
      }

      .participants {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: var(--primary);
        font-size: 0.9rem;
        font-weight: 500;
        margin: 0 0 0.5rem;

        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0;
          margin: 0;

          svg {
            width: 25px;
          }
        }

        &:first-child {
          align-self: flex-start;
          justify-content: flex-start;

          svg {
            margin-right: 10px;
          }
        }

        &:last-child {
          align-self: flex-end;
          justify-content: flex-end;

          svg {
            margin-left: 10px;
          }
        }

        svg {
          fill: var(--primary);
          margin: 0 10px 0 0;
          aspect-ratio: 1/1;
          width: 30px;
        }
      }

      .attributeProperty {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 2rem;
        width: 100%;

        h1 {
          font-size: 1rem;
          font-weight: 300;
          margin: 0;
        }

        span {
          font-size: 1rem;
          font-weight: 300;
          margin: 0 0 0 15px;
        }
      }

      img {
        width: 100%;
        object-fit: cover;
        border-radius: var(--border-radius);
        margin-bottom: 1rem;
        transition: filter 300ms ease-in-out;
        cursor: pointer;

        &:hover {
          filter: brightness(1.2);
        }
      }
    }

    .infoPanelRight {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 2rem;
      width: 100%;

      .share {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: var(--text-color);
        font-size: 1.5rem;
        font-weight: 400;
        width: 100%;
        min-height: 80px;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        background-color: var(--background-accent);
        border-radius: var(--border-radius);
        padding: 15px 1.5rem;
        transition: 300ms all ease-in-out;
        cursor: pointer;

        svg {
          aspect-ratio: 1/1;
          width: 50px;
          fill: var(--primary);
          margin: 0;
        }

        span {
          font-size: 1.5rem;
          font-weight: 600;
          margin: 0;
        }

        &:hover {
          filter: brightness(1.2);
        }
      }

      .raffleInfoWinner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        background-color: var(--background-accent);
        border-radius: var(--border-radius);
        padding: 15px 1.5rem;
        transition: filter 300ms ease-in-out;

        div {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          img {
            margin-right: 16px;
            aspect-ratio: 1/1;
            width: 64px;
            border: 3px solid var(--primary);
            border-radius: 100%;
          }

          @media screen and (max-width: 520px) {
            flex-direction: column;
          }

        }

        &:hover {
          filter: brightness(1.2);
        }
      }
    }
  }
}

// for screens 860px & below
@media screen and (max-width: 960px) {
  .raffleInfo {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 2rem;

    .infoPanel {
      flex-direction: column;
      align-items: center;
      padding-left: 0;


      .infoPanelLeft {
        width: 100%;
        margin-bottom: 2rem;
        .title {
          display: flex;

          span {
            margin-left: 10px;
          }
        }
        .titleName {
          display: unset;
          margin-top: -26px;
          font-size: 2rem;
        }

        img {
          width: 100%;
        }
      }

      .infoPanelRight {
        width: 100%;
        padding: 0;

        .title {
          display: none;
        }
      }
    }
  }
}