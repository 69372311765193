@import '../../variables.scss';


@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }

  80% {
    transform: rotate(0deg);
  }

  85% {
    transform: rotate(2deg);
  }

  95% {
    transform: rotate(-2deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes randomFill {
  0% {
    fill: #FBE76E;
  }

  25% {
    fill: $pColor;
  }

  50% {
    fill: $sColor;
  }

  75% {
    fill: #D5FD8E;
  }

  100% {
    fill: #FBE76E;
  }
}

.navBarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 80px;
  z-index: 100;
  transition: all 0.4s ease-in-out;

  .navItem {
    opacity: 0;
    transition: all 0.4s ease-in-out;
  }

}

.userBtn {
    cursor: pointer;
    height: max-content;
    width: max-content;
    border-radius: 12px;
    background-color: #0aab15;
    color: #fff;
    font-size: 18px;
    padding: 15px 20px;
    border: none;
    transition: all 0.4s ease-in-out;

    &:hover {
      opacity: .8;
      transition: all 0.4s ease-in-out;
    }
}

.navBarContainerActive {
  background-color: #0000008a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 80px;
  z-index: 100;
  width: 100%;
  backdrop-filter: blur(10px);
  transition: all 0.4s ease-in-out;
}

.navItemContainer {
  display: grid;
  grid-template-columns: .5fr .5fr .5fr .5fr .5fr .5fr;
  ;
  gap: 20px;
}

.actionContainer {
  display: grid;
  grid-template-columns: .2fr .2fr .2fr .2fr .2fr .2fr;
  gap: 20px;
}



.socialIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  svg {
    fill: #FFF;
    height: 30px;
    width: 30px;
    filter: drop-shadow(2px 2px 4px #000000);
  }

  &:hover svg {
    fill: var(--primary);
    //animation: randomFill 4s infinite;
  }

}

.dashButton {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;


  h2 {
    color: #fff;

  }

}

.navLogo {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    padding: 10px;
    height: auto;
    width: 200px;
  }
}

.alphi {
  display: flex;
    justify-content: center;
    align-items: center;

  svg {
    width: 50px;
    fill: white;
    transition: fill 200ms ease-in-out;

    &:hover {
      fill: var(--primary);
    }
  }

  img {
    width: 30px;
    filter: brightness(100) contrast(1);
  }
}

.navItem, .activeNavItem {
  background: transparent;
  border: none;
  font-family: $pFont;
  font-size: 25px;
  color: #FFF;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-in-out;

  &:hover {
    //transform: rotate(2deg);
    color: var(--primary);
    transition: all .2s ease-in-out;
  }

}

.activeNavItem {
    color: var(--primary);
    transition: all .2s ease-in-out;
}

.stakeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: var(--primary);
  height: max-content;
  margin: auto 0;
  border-radius: var(--border-radius);
  border: none;
  font-family: var(--font);
  padding: 10px;
  font-size: 20px;
  color: #FFF;
  cursor: pointer;
  font-weight: 500;
  //box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 3px, rgba(0, 0, 0, 0.23) 0px 3px 3px;
  transition: all 200ms ease-in-out;

  &:hover {
    filter: brightness(1.2);
    //animation: none;
    //transform: rotate(-2deg);
    //box-shadow: rgba(0, 0, 0, 0.16) 0px 6px 6px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    //transition: all .2s ease-in-out;
  }
}

.mobileNavContainer,
.mobileNavContainerActive {
  display: none;
  height: 0px;
  width: 0px;
}

@media only screen and (max-width: 1200px) {

  .navBarContainer,
  .navBarContainerActive {
    display: none;
    width: 0px;
    height: 0px;
  }

  @keyframes wiggle {
    0% {
      transform: rotate(0deg);
    }

    80% {
      transform: rotate(0deg);
    }

    85% {
      transform: rotate(2deg);
    }

    95% {
      transform: rotate(-2deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }


  @keyframes appear {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes slideIn {
    0% {
      right: -100%;
    }

    100% {
      right: 0;
    }
  }

  @keyframes closeSlideIn {
    0% {
      right: -100%;
    }

    100% {
      right: 62%;
    }
  }

  .sideBar {
    right: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 60%;
    z-index: 201;
    background-color: #000000c5;
    padding: 20px 10px;
    animation: slideIn .4s;
  }

  .whoop {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    h2 {
      margin: 0;
      color: #FFF;
      //text-shadow: 2px 2px #000000;
    }


  }

  .navItem {
    font-size: 1.4em;
  }

  .navItemContainer {
    display: flex;
    grid-template-columns: none;
  }

  .actionContainer {
    display: flex;
    grid-template-columns: none;
    justify-content: flex-end;
    gap: 20px;
  }

  .close {
    position: fixed;
    right: 62%;
    top: 2%;
    background-color: transparent;
    border: none;

    svg {
      fill: #FFF;
      height: 25px;
      width: 25px;
    }

    animation: closeSlideIn .4s;
  }

  .sideBarBg {
    position: fixed;
    width: 100%;
    height: 100vh;
    content: "";
    background-color: #0000009f;
    z-index: 201;
    animation: appear .4s;
  }


  .mobileNavContainer {
    position: fixed;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: auto;
    z-index: 100;
    transition: all 0.4s ease-in-out;

    img {
      height: auto;
      width: 150px;
    }
  }

  .mobileNavContainerActive {
    position: fixed;
    background-color: #0000008a;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: auto;
    z-index: 100;
    backdrop-filter: blur(10px);
    transition: all 0.4s ease-in-out;

    img {
      height: auto;
      width: 150px;
    }
  }

  .mobileNavButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;

    svg {
      fill: rgb(255, 255, 255);
      height: 25px;
      width: 25px;
    }
  }

  .mobileLinks {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
  }

  .navLogo {
    img {
      padding: 10px 0;
      height: auto;
      width: 150px;
    }
  }

  .socialLinks {
    display: flex;
    width: 100%;
    justify-content: space-evenly;

    svg {
      //animation: randomFill 4s infinite;
    }
  }

  .stakeButton {
    display: flex;
    justify-content: center;
    text-decoration: none;
    background-color: var(--font);
    border-radius: var(--border-radius);
    padding: 5px;
    border: none;
    font-family: var(--font);
    font-size: 25px;
    color: #FFF;
    margin: 10px auto;
    cursor: pointer;
    font-weight: 500;
    //box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 3px, rgba(0, 0, 0, 0.23) 0px 3px 3px;
    transition: all 200ms ease-in-out;

    &:hover {
      //animation: none;
      //transform: rotate(-2deg);
      //box-shadow: rgba(0, 0, 0, 0.16) 0px 6px 6px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
      //transition: all .2s ease-in-out;
    }
  }

}