.activityWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;
}

.activity {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  align-self: center;
  background: var(--background-accent);
  // margin-bottom: 2rem;
  cursor: pointer;
  padding: 0 2rem;

  h1 {
    font-size: 1.8rem;
    font-weight: 500;
    color: var(--primary);
    font-family: var(--font);
  }

  p {
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--text-color-accent);
    font-family: var(--font);
  }
  .viewButton {
    display: flex;
    background: #3f3f3f;
    border-radius: var(--border-radius);
    padding: 0.5rem;
    svg {
      fill: var(--background-accent);
      aspect-ratio: 1/1;
      width: 25px;
      margin: 0;
      cursor: pointer;
    }

    &:hover {
      filter: brightness(1.2);
    }
  }

  &:hover {
    filter: brightness(1.2);
  }

  @media (max-width: 860px) {
    p {
        display: none;
    }
  }
}

.details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  align-self: center;
  padding: 1rem 2rem;
  background: #282828;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  height: 0;
  overflow: hidden;
  transition: all 300ms ease-out;

  p {
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--text-color-accent);
    font-family: var(--font);
    margin: 0.5rem 0;

    span {
      color: var(--primary);
      font-weight: 500;
    }
  }
  img {
    aspect-ratio: 1/1;
    width: 200px;
    border-radius: var(--border-radius);
    margin-left: 20px;
  }
}

.detailsExpanded {
  height: auto;
  transition: all 300ms ease-in;

  svg {
    aspect-ratio: 1/1;
    align-self: center;
    width: 25px;
  }
}