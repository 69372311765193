@import '../../variables.scss';


@keyframes bounceOut {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(2px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes bounceIn {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-2px);
    }

    100% {
        transform: translateX(0);
    }
}

.aboutDashContainer {
    display: flex;
    flex-direction: column;
    padding: 0 20px 0 20px;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
}

.trendingContainer {
    position: relative;
    background-color: #8b8b8b18;
    backdrop-filter: blur(10px);
    padding: 50px;
    border-radius: var(--border-radius);
    display: grid;
    grid-template-columns: .5fr 1fr;
    gap: 40px;

    .trendingDetail {
        font-size: 1rem;
        font-weight: 300;
        color: var(--text-color-accent);
        margin: 0 0 20px;
    }
}

.trendingImageContainer {
    position: relative;
    width: 100%;
    height: 100%;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        //box-shadow: 2px 10px 10px #0000003d;
        border-radius: var(--border-radius);
    }
}

.img {
    position: absolute;
    z-index: -1;
    opacity: 0;
    right: 100vw;
}

.imgActive {
    opacity: 1;
    transition: all 0.6s ease-in;
}

.trendingDescription {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.headerActivePrim {
    opacity: 1;
    color: var(--primary);
    //text-shadow: 2px 2px 2px #000000;
    transition: opacity 0.6s ease-in;
}

.headerActive {
    opacity: 1;
    color: var(--text-color);
    //text-shadow: 2px 2px 2px #000000;
    transition: opacity 0.6s ease-in;
    font-size: 1.2rem;
    font-weight: 300;
}

.header {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.buttonNext {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000067;
    border: none;
    border-radius: var(--border-radius);
    position: absolute;
    right: 25px;
    top: 50%;
    padding: 15px;
    //box-shadow: 0px 2px 8px #0000003d;

    svg {
        fill: var(--text-color);
        height: 15px;
        width: 15px;
    }

    &:hover {
        animation: bounceOut .6s ease-in-out 2;
    }
}

.buttonLast {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000067;
    border: none;
    border-radius: var(--border-radius);
    position: absolute;
    left: 35%;
    top: 50%;
    padding: 15px;
    font-family: var(--font);
    //box-shadow: 0px 2px 8px #0000003d;

    svg {
        transform: rotate(180deg);
        fill: #ffffff;
        height: 15px;
        width: 15px;
    }

    &:hover {
        animation: bounceIn .6s ease-in-out 2;
    }
}

.actions {
    display: flex;
    justify-content: space-between;
}

.button {
    width: 100%;
    text-decoration: none;
    display: flex;
    font-family: var(--font);

    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    // background-color: #00000057;
    //box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 8px;
    border: none;
    padding: 15px 30px;
    border-radius: var(--border-radius);
    color: var(--text-color);
    font-size: larger;
    transition: all 200ms ease-in-out;

    &:last-of-type {
        margin-left: 20px;
    }

    &:hover {
        filter: brightness(1.2);
        //transform: translateY(-2px);
        //text-shadow: 0px 0px 10px #ffffff6b;
        //box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 10px;
        transition: all 200ms ease-in-out;
    }
}

@media screen and (max-width: 767px) {

    .aboutDashContainer {
        width: 100%;
        padding: 0;
    }

    .trendingContainer {
        padding: 20px;
        display: flex;
        flex-direction: column-reverse;
        gap: 0;

        h2 {
            font-size: medium;
            margin-bottom: 20px;
        }

        .header:not(h3),
        .headerActive:not(h3) {
            //font-size: larger;
        }

        h3 {
            font-size: medium;
            margin-bottom: 30px;
        }
    }

    .buttonNext {
        right: -10px;
        top: 15vh;
    }

    .buttonLast {
        left: -10px;
        top: 15vh;
    }

    .trendingImageContainer {
        padding-bottom: 30px;
    }

}